import { Component, OnInit } from "@angular/core";
import { NgModel } from "@angular/forms";
import { VmSku } from "src/app/generated-models";
import {
  BatchRequestType,
  DeploymentScope,
  IAppServiceQuotaUI,
  IDetailsInfo,
  IHdiSkus,
  IRequestRegion,
  IRequestVmSku, IVmDiskQuotaInfo,
  SkuType,
  SqlMiType,
  SqlPurchaseModelType,
} from "src/app/npr-request.model";
import { ApiService } from "src/app/services";
import { CloudName } from "src/app/shared/ticket-details/ticket-column-definition";
import { isNonEmptyString } from "src/app/utility/common-helper";
import { environment } from "src/environments/environment";
import { NprConfiguration } from "../../../app.configuration";
import { CreateRequestService } from "../create-request.service";

@Component({
  selector: "request-details",
  templateUrl: "./details-info.component.html",
  styleUrls: ["../create-request.scss"],
})
export class DetailsInfoComponent implements OnInit {
  detailsInfo: IDetailsInfo;
  region: IRequestRegion;
  sqlMiVcoresList = [4, 8, 16, 24, 32, 40, 64, 80];
  sqlMiSubNetList = Array.from(new Array(15).keys()).map((i) => i + 1);
  deploymentScopeList = [DeploymentScope.Regional, DeploymentScope.Zonal];
  rdfeStorageLimits: [number, number] = [1, 100];
  armStorageLimits: [number, number] = [100, 500];
  sqlMiType = SqlMiType;
  sqlPurchaseModelType = SqlPurchaseModelType;
  hdiSkuList: IHdiSkus[] = [];
  isRegionLive = false;
  isDeploymentScopeEnabled = false;
  serviceStatus: Map<string, string>;
  rdfeSkuList: IRequestVmSku[] = [];
  hasExistingCluster = false;
  batchAccountNameRegExpression = new RegExp("^[a-z0-9]{3,24}$");
  serviceTreeIdRegExpression = NprConfiguration.guidRegExpression;  // serivce tree pattern is the same as sub
  public isExternal = false;
  vmDisksSkuList: IVmDiskQuotaInfo[] = [];

  defaultQuota = {
    SqlDtu: 54000,
    SqlServer: 20,
  };

  maxQuota = {
    SqlServer: 250,
  }

  // Service disabled
  serviceDisabled = {
    AZ: false,
    ARM: false,
    RDFE: false,
    CAS: false,
    ArmVmQuota: false,
    RdfeVmQuota: false,
    ArmStorageQuota: false,
    RdfeStorageQuota: false,
    SQL: false,
    SQLMI: false,
    DNSEntry: false,
    BatchQuota: false,
    HDInsight: false,
    CosmosDB: false,
    CosmosDBAz: false,
    Kusto: false,
    AppService: false,
    RPFrontload: false,
    ArmResourceType: false,
    VmDisks: false,

    // Non-standard service type
    RegionEnable: false,
    ArmVmRegionalQuota: false,
    ArmLowPriorityQuota: false,
    StorageQuota: false,
    SqlDtuQuota: false,
    SqlServerQuota: false,
  };

  // App service VM type
  appServiceVmTypes = ["Windows", "Linux"];

  constructor(private apiService: ApiService, public service: CreateRequestService) {
    this.detailsInfo = service.detailsInfo;
    this.region = service.basicInfo.region;
    this.isExternal = service.basicInfo.isExternal;
  }

  async ngOnInit(): Promise<void> {
    const region: IRequestRegion = this.region;

    // Get service status
    const serviceStatusResp = (await this.apiService?.getServiceStatus(region.label, this.service.isFlighting).toPromise()) ?? {};
    const vmSkuList: VmSku[] = (await this.apiService?.getVmSkusByRegion(region.label).toPromise()) ?? [];
    const armResourceTypeEntities = (await this.apiService?.getArmResourceTypeByRegion(region.label).toPromise()) ?? [];
    const vmDiskEntities = (await this.apiService?.getVmDiskByRegion(region.label).toPromise()) ?? [];

    armResourceTypeEntities.forEach(e => {
      this.service.armResourceTypes.push(`${e.Namespace}/${e.ResourceTypeName}`);
    });

    vmDiskEntities.forEach(e => {
      const vmDiskInfo = { label: e.QuotaName, value: e.QuotaType, maxQuotaLimit: e.MaxQuotaLimit };
      this.service.vmDisks.push(vmDiskInfo);
      this.vmDisksSkuList.push(vmDiskInfo)
    })

    const azEntities = ((await this.apiService?.getAzEntity(false).toPromise()) ?? []).filter((_) => {
      return _.DCMTRegionId === this.region.dcmtRegionId;
    });

    this.serviceDisabled.AZ = true;
    azEntities.forEach((az) => {
      if (az.IsSupportedInGenevaAction) {
        this.detailsInfo.azEntities.push(az.AvailabilityZoneName);
      }
      if (az.RequiredFeature) {
        this.serviceDisabled.AZ = false;
      }
    });

    this.serviceStatus = new Map(Object.entries(serviceStatusResp));
    this.initServiceDisabled();

    const rdfeStorageMaxLimit = environment.validateOptions?.RdfeStorageMaxLimit;
    if (rdfeStorageMaxLimit !== null) {
      this.rdfeStorageLimits[1] = rdfeStorageMaxLimit;
    }
    const rdfeStorageMinLimit = environment.validateOptions?.RdfeStorageMinLimit;
    if (rdfeStorageMinLimit != null) {
      this.rdfeStorageLimits[0] = rdfeStorageMinLimit;
    }

    const armStorageMaxLimit = environment.validateOptions?.ArmStorageMaxLimit;
    if (armStorageMaxLimit !== null) {
      this.armStorageLimits[1] = armStorageMaxLimit;
    }
    const armStorageMinLimit = environment.validateOptions?.ArmStorageMinLimit;
    if (armStorageMinLimit != null) {
      this.armStorageLimits[0] = armStorageMinLimit;
    }

    this.hdiSkuList = [
      ...new Set(
        this.service.hdiSkusInAllRegions
          ?.filter((s) => region.label === s.RegionName)
          .map((s) => ({ label: s.PortalVmSku, value: s.CrpVmSku }))
      ),
    ];
    this.service.vmSkuList = this.service.vmSkuList.map((sku) => {
      const target = vmSkuList.find(s => sku.label === s.PortalVmSku || sku.label === s.CrpVmSku);
      if (!target || !target.SupportedInCrp) {
        sku.disabled = true;
        sku.skuType = SkuType.Disabled;
      } else {
        sku.disabled = false;
        sku.skuType = target.IsInternal ? SkuType.Internal : SkuType.Compute;
      }
      return sku;
    });

    this.rdfeSkuList = this.service.vmSkuList.filter((sku) => sku.skuType === SkuType.Compute);

    this.service.batchSkuList = this.service.batchSkuList.filter((s) => s.region === region.label || s.region == "all" ).map((sku) => {
      if (sku.requestType !== BatchRequestType.Dedicated) {
        return sku;
      }
      sku.disabled = !vmSkuList.some((s) => s.PortalVmSku === sku.label || sku.label === s.CrpVmSku);
      return sku;
    });
    this.isRegionLive = region.isGA;
    this.isDeploymentScopeEnabled = this.detailsInfo.azEntities.length >= 3;
    this.detailsInfo.cosmosDB.CosmosSkuList = [
      {
        label: "Throughput limit",
        sku: "ThroughputLimit",
        quota: null,
      }
    ];

    this.detailsInfo.isValidateBtnDisabled = () => {
      let disabled = false;
      if (this.detailsInfo.sql && this.isRegionLive) {
        disabled = disabled || !this.isQuotaValid(this.detailsInfo.sqlQuota) || this.detailsInfo.sqlPurchaseModel === null;
      }
      if (this.detailsInfo.sqlDtu) {
        disabled =
          disabled ||
          !this.isQuotaValid(this.detailsInfo.sqlDtuQuota, this.defaultQuota.SqlDtu) ||
          !isNonEmptyString(this.detailsInfo.sqlServerName);
      }
      if (this.detailsInfo.sqlServer) {
        disabled =
          disabled ||
          !this.isQuotaValid(this.detailsInfo.sqlServerQuota, this.defaultQuota.SqlServer, this.maxQuota.SqlServer);
      }
      if (this.detailsInfo.sqlMI) {
        if (this.detailsInfo.sqlMiType === SqlMiType.DetailInstances) {
          disabled =
            disabled ||
            !this.isListItemValid(this.detailsInfo.sqlMiGPvCores, this.sqlMiVcoresList) ||
            !this.isQuotaValid(this.detailsInfo.sqlMiGPCount) ||
            !this.isListItemValid(this.detailsInfo.sqlMiBCvCores, this.sqlMiVcoresList) ||
            !this.isQuotaValid(this.detailsInfo.sqlMiBCCount) ||
            !this.isListItemValid(this.detailsInfo.sqlMiSubNet, this.sqlMiSubNetList);
        } else if (this.detailsInfo.sqlMiType === SqlMiType.TotalvCores) {
          disabled =
            disabled ||
            !this.isQuotaValid(this.detailsInfo.sqlMiTotalvCores) ||
            !this.isListItemValid(this.detailsInfo.sqlMiSubNet, this.sqlMiSubNetList);
        }
      }
      if (this.detailsInfo.hdi) {
        disabled =
          disabled ||
          !this.isQuotaValid(this.detailsInfo.hdiQuota) ||
          !this.isListItemValid(
            this.detailsInfo.hdiSku?.value,
            this.hdiSkuList.map((h) => h.value)
          );
      }
      if (this.detailsInfo.dns.IsSubsriptionQuota || this.detailsInfo.dns.IsZoneQuota) {
        disabled = disabled || !this.isDNSValid;
      }
      if (this.detailsInfo.requireComputeArmVmQuota) {
        disabled = disabled || !this.isListItemsValid(this.detailsInfo.vmQuota, this.service.vmSkuList) || !this.isVmQuotaValid;
        if (this.isDeploymentScopeEnabled) {
          disabled = disabled || !this.isListItemValid(this.detailsInfo.deploymentScope, this.deploymentScopeList);
        }
      }
      if (this.detailsInfo.requireComputeArmSharedQuota) {
        disabled = disabled || !this.isQuotaValid(this.detailsInfo.computeArmSharedQuota);
      }
      if (this.detailsInfo.requireComputeArmLowPriorityQuota) {
        disabled = disabled || !this.isQuotaValid(this.detailsInfo.computeArmLowPriorityQuota);
      }
      if (this.detailsInfo.requireComputeRdfeVmQuota) {
        disabled =
          disabled ||
          !this.isQuotaValid(this.detailsInfo.computeRdfeVmQuota) ||
          !this.isListItemValid(this.detailsInfo.rdfeVmSku, this.service.vmSkuList);
      }
      if (this.detailsInfo.additionalStorage) {
        if (this.detailsInfo.storageQuotaARM) {
          disabled = disabled || !this.isStorageQuotaArmValid;
        }
        if (this.detailsInfo.storageQuotaRDFE) {
          disabled = disabled || !this.isStorageQuotaRdfeValid;
        }
        if (!this.detailsInfo.storageQuotaARM && !this.detailsInfo.storageQuotaRDFE) {
          disabled = true;
        }
      }

      if (this.detailsInfo.regionEnablement) {
        disabled = disabled || !(this.detailsInfo.regionEnablementARM || this.detailsInfo.regionEnablementRDFE);
      }

      if (this.detailsInfo.requireBatchAccounts) {
        disabled = disabled || !this.isBatchAccountQuotaValid;
      }

      if (this.detailsInfo.requireBatchComputeQuota) {
        if (!this.isBatchAccountNameValid || !(this.detailsInfo.batchComputeQuota.length > 0)) {
          return true;
        }
        disabled = disabled || !this.isBatchComputeQuotaValid || !this.isBatchJobQuotaValid;
      }

      if (this.detailsInfo.cosmosDB.IsSubscriptionAccounts) {
        disabled = disabled || !this.isQuotaValid(this.detailsInfo.cosmosDB.SubscriptionAccounts);
      }

      if (this.detailsInfo.cosmosDB.IsAccountQuota) {
        disabled = disabled || !this.isCosmosDBAccountQuotaValid;
      }

      if (this.detailsInfo.rpFrontload) {
        disabled = disabled || !this.isNamespacesSelected() || !this.isServiceTreeIdValid(this.detailsInfo.serviceTreeId);
      }

      if (this.detailsInfo.requireVmDisks) {
        disabled = disabled ||
          !this.isVmDisksQuotaValid(this.detailsInfo.vmDisksSku?.value, this.detailsInfo.vmDisksQuota) ||
          !this.isListItemValid(
            this.detailsInfo.vmDisksSku?.value,
            vmDiskEntities.map((h) => h.QuotaType)
          );
      }

      // should select at last one checkbox when not create new sub
      disabled = disabled || !this.isAnyCheckboxSelected;

      return disabled;
    };
  }
  isVMSkuSelected(): boolean {
    return this.detailsInfo.vmQuota?.length > 0;
  }

  isTouchedOrDirty(control: NgModel): boolean {
    return control && (control.touched || control.dirty);
  }

  isQuotaValid(quota: number, min = 0, max?: number): boolean {
    return !isNaN(quota) && quota > min && (max != null && max > min ? quota < max : true);
  }

  maxVmDiskQuotaLimit(quotaType: string): number {
    for (let i = this.vmDisksSkuList.length - 1; i >= 0; i--) {
      if (this.vmDisksSkuList[i].value === quotaType) {
        return this.vmDisksSkuList[i].maxQuotaLimit;
      }
    }
    return -1;
  }

  isVmDisksQuotaValid(quotaType: string, quota: number): boolean {
    return this.isQuotaValid(quota, 0, this.maxVmDiskQuotaLimit(quotaType));
  }

  isListItemValid<T>(value: T, items: T[]): boolean {
    return items.some((item) => item === value);
  }

  isListItemsValid<T>(value: T[], items: T[]): boolean {
    return items?.some((item) => value.includes(item));
  }

  isAppServiceQuotaValid(appServiceQuotaItem: IAppServiceQuotaUI): boolean {
    return this.isQuotaValid(appServiceQuotaItem?.windowsVmQuota) || this.isQuotaValid(appServiceQuotaItem?.linuxVmQuota);
  }

  isServiceTreeIdValid(serviceTreeId: string): boolean {
    return new RegExp(this.serviceTreeIdRegExpression).test(serviceTreeId);
  }

  isNamespacesSelected(): boolean {
    return this.detailsInfo.namespaces?.length > 0;
  }

  onZoneChange(email:string, isChecked: boolean) {
    var result = [];
    if(isChecked) {
      result.push(email);
    }
  }

  initServiceDisabled(): void {
    this.serviceStatus.forEach((value, key) => {
      if (value === "Disabled" || value === "NotAvailable") {
        this.serviceDisabled[key] = true;
      }
    });

    if (!this.region.isManagedRegion && this.region.isGA) {
      this.serviceDisabled.ARM = true;
    }

    if (this.service.basicInfo.isExternal) {
      this.serviceDisabled.RDFE = true;
    }

    if (this.region.cloud === CloudName.USNat || this.region.cloud === CloudName.USSec) {
      this.serviceDisabled.SQL = true;

      if (this.region.cloud === CloudName.USNat) {
        this.serviceDisabled.HDInsight = true;
        this.serviceDisabled.RegionEnable = true;
      }
    }

    if (this.region.label.endsWith("EUAP")) {
      this.serviceDisabled.SqlDtuQuota = true;
    }

    // Disable CosmosDB AZ Enablment if region is not restricted
    if (!this.service.restrictedRegionsForCosmosDB.includes(this.region.label)) {
      this.serviceDisabled.CosmosDBAz = true;
    }

    if (this.service.armResourceTypes?.length == 0) {
      this.serviceDisabled.ArmResourceType = true;
    }
  }

  get sqlMiTypeEqPTC(): boolean {
    return this.detailsInfo.sqlMiType === SqlMiType.TotalvCores;
  }
  get sqlMiTypeEqPDI(): boolean {
    return this.detailsInfo.sqlMiType === SqlMiType.DetailInstances;
  }
  get isDNSValid(): boolean {
    var dns = this.detailsInfo.dns;
    return this.isQuotaValid(dns?.maxZoneQuota) || this.isQuotaValid(dns?.maxRecordSetQuota) || this.isQuotaValid(dns?.zoneRecordSetQuota);
  }

  get isDNSQuotaValid(): boolean {
    if (this.detailsInfo.dns?.maxZoneQuota === null || this.detailsInfo.dns?.maxZoneQuota === undefined) {
      return true;
    }
    return this.isQuotaValid(this.detailsInfo.dns?.maxZoneQuota, 0, 10001);
  }

  get isDNSRecordSetQuota(): boolean {
    if (this.detailsInfo.dns?.maxRecordSetQuota === null || this.detailsInfo.dns?.maxRecordSetQuota === undefined) {
      return true;
    }
    return this.isQuotaValid(this.detailsInfo.dns?.maxRecordSetQuota, 0, 25001);
  }

  get isVmQuotaValid(): boolean {
    return !this.detailsInfo.vmQuota?.some((s) => s.checked && (!s.quota || s.quota < 1));
  }

  get isStorageQuotaArmValid(): boolean {
    return this.isQuotaValid(this.detailsInfo.storageQuotaARM, this.armStorageLimits[0] - 1, this.armStorageLimits[1] + 1);
  }
  get isStorageQuotaRdfeValid(): boolean {
    return this.isQuotaValid(this.detailsInfo.storageQuotaRDFE, this.rdfeStorageLimits[0] - 1, this.rdfeStorageLimits[1] + 1);
  }

  get isHdiSkuValid(): boolean {
    return this.isListItemValid(
      this.detailsInfo.hdiSku?.value,
      this.hdiSkuList.map((v) => v.value)
    );
  }

  get isVmDisksSkuValid(): boolean {
    return this.isListItemValid(
      this.detailsInfo.vmDisksSku?.value,
      this.vmDisksSkuList.map((v) => v.value)
    )
  }

  get isBatchAccountQuotaValid(): boolean {
    if (this.detailsInfo.requireBatchAccounts) {
      return this.detailsInfo.batchTotalAccounts > 0;
    }
    return true;
  }
  get isBatchComputeQuotaValid(): boolean {
    if (this.detailsInfo.requireBatchComputeQuota) {
      return !this.detailsInfo.batchComputeQuota.some((s) => !s.quota || s.quota < 1);
    }
    return true;
  }
  get isBatchJobQuotaValid(): boolean {
    if (this.detailsInfo.requireBatchComputeQuota) {
      return !this.detailsInfo.batchComputeQuota.some(
        (s) => s.requestType === BatchRequestType.JobQuota && (s.quota <= 100 || s.quota > 1000)
      );
    }
    return true;
  }

  get isCosmosDBAccountQuotaLimitsValid(): boolean {
    if (this.detailsInfo.cosmosDB.IsAccountQuota) {
      return !this.detailsInfo.cosmosDB.AccountQuotas?.some((s) => !s.quota || s.quota < 1);
    }
    return true;
  }

  get isCosmosDBAccountQuotaValid(): boolean {
    if (this.detailsInfo.cosmosDB.IsAccountQuota) {
      if (
        this.detailsInfo.cosmosDB.AccountName &&
        this.detailsInfo.cosmosDB.AccountQuotas?.length > 0 &&
        this.isCosmosDBAccountQuotaLimitsValid
      ) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  get isAppServiceQuotaSelected(): boolean {
    return this.detailsInfo.appServiceQuota?.length > 0;
  }

  get isArmResourceTypeSelected(): boolean {
    return this.detailsInfo.armResourceTypes?.length > 0;
  }

  get isBatchAccountNameValid(): boolean {
    if (this.detailsInfo.requireBatchComputeQuota) {
      return (
        isNonEmptyString(this.detailsInfo.batchAccountName) && this.batchAccountNameRegExpression.test(this.detailsInfo.batchAccountName)
      );
    }
    return true;
  }

  get isAnyCheckboxSelected(): boolean {
    return (
      this.detailsInfo.sql ||
      this.detailsInfo.sqlDtu ||
      this.detailsInfo.sqlServer ||
      this.detailsInfo.sqlMI ||
      this.detailsInfo.hdi ||
      this.detailsInfo.dns.IsSubsriptionQuota ||
      this.detailsInfo.dns.IsZoneQuota ||
      this.detailsInfo.requireComputeArmSharedQuota ||
      this.detailsInfo.requireComputeArmVmQuota ||
      this.detailsInfo.requireComputeArmLowPriorityQuota ||
      this.detailsInfo.requireComputeRdfeVmQuota ||
      this.detailsInfo.additionalStorage ||
      this.detailsInfo.azEnablement ||
      this.detailsInfo.regionEnablement ||
      this.detailsInfo.requireBatchComputeQuota ||
      this.detailsInfo.requireBatchAccounts ||
      this.detailsInfo.cosmosDB.IsAccess ||
      this.detailsInfo.cosmosDB.IsAzEnablement ||
      this.detailsInfo.cosmosDB.IsAccountQuota ||
      this.detailsInfo.cosmosDB.IsSubscriptionAccounts ||
      this.detailsInfo.kusto ||
      this.detailsInfo.requireAppServiceQuota ||
      this.detailsInfo.rpFrontload ||
      this.detailsInfo.enableArmWithNoQuota ||
      this.detailsInfo.requireArmResourceType ||
      this.detailsInfo.requireVmDisks
    );
  }
}
