import { Component } from "@angular/core";
import { ColumnApi, ExcelExportParams, ExcelStyle, GridApi, GridOptions, SelectionChangedEvent } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../services";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { FfpPlanInstanceStatusEnum } from "src/app/shared/enums/plan-enums";
import { GridViewBaseComponent } from "../../utility/grid-view-base.component";
import { ModalService } from "../../shared/modal.service";
import { SharedDataService } from "../../services/sharedDataService";
import { getExportedExcelFileNameSuffix } from "src/app/utility/common-helper";
import { BuildoutPlansColumnDefinition } from "./ag-grid-column-definition";
import { ActivatedRoute, Router } from "@angular/router";
import { FfpPlanInstance } from "../../generated-models/FfpPlanInstance";
import { Project } from "../../generated-models/Project";

@Component({
  templateUrl: "./ffp-buildout-plans.component.html",
  styleUrls: ["../../styles.scss", "./ffp.scss"],
})
export class FfpBuildoutPlansComponent extends GridViewBaseComponent {
  gridApi: GridApi;
  gridOptions: GridOptions;
  gridColumnApi: ColumnApi;
  initPageSize: number = 15;
  projectOid: string;
  project: Project;
  keyFilterStats = "filter:ffp-buildout-plans";
  rowData: FfpPlanInstance[] = [];
  headerStyle: ExcelStyle[] = [
    {
      id: "header",
      font: {
        bold: true,
      },
    },
  ];

  selectedRowCount = 0;
  approvedPlansCount = 0;
  loadCheckBox = false;
  isFfpAdmin = false;
  isAdmin = false;


  constructor(
    private readonly route: ActivatedRoute,
    private router: Router,
    protected apiService: ApiService,
    private loadingService: LoadingScreenService,
    private notificationService: ToastrService,
    protected sharedDataService: SharedDataService,
    protected modalService: ModalService
  ) {
    super(apiService, sharedDataService);
  }

  async ngOnInit() {
    this.projectOid = this.route.snapshot.params["projectOid"];
    this.project = await this.apiService.getProject(this.projectOid).toPromise();
    BuildoutPlansColumnDefinition.push({
      colId: "customFilter",
      headerName: "Custom Filter",
      valueGetter: () => {
        return true;
      },
      filterParams: {
        values: ["true", "false"],
        newRowsAction: "keep",
      },
      hide: true,
    });
    super.InitAgGrid(BuildoutPlansColumnDefinition);
    this.apiService.getUserProfile().then(async (response) => {
      this.userProfile = response;
      if (response) {
        this.gridApi?.showLoadingOverlay();
        if (response?.IsFfpAdmin) {
          this.isFfpAdmin = true;
        }
        if (response?.IsAdmin) {
          this.isAdmin = true;
        }
      }
    });
  }

  onGridReady(params: GridOptions): void {
    super.onGridReady(params);
    this.gridApi.setFilterModel({
      customFilter: ["true"],
    });
  }

  onShowRejectedChanged(): void {
    this.gridApi.onFilterChanged();
  }

  refreshData() {
    this.selectedRowCount = 0;
    this.loadingService.setLoading(true);
    this.apiService.getAllLatestFfpPlansByProject(this.projectOid).subscribe(
      (response) => {
        this.rowData = response;
        this.approvedPlansCount = this.rowData.filter((r) => r.Status === FfpPlanInstanceStatusEnum.Approved).length;
        this.loadingService.setLoading(false);
        super.setupFilterByLocalData();
      },
      (e: any) => {
        this.rowData = [];
        this.loadingService.setLoading(false);
        this.notificationService.error(e);
      }
    );
    this.setActionCheckBoxAttributes();
  }

  async batchApproveFfpPlan(formValues?: FfpPlanInstance[]) {
    const selectedPlanProject: FfpPlanInstance[] = formValues ? formValues : this.gridApi.getSelectedRows();
    if (!selectedPlanProject && selectedPlanProject.length === 0) {
      this.message = "Please select Plan Project(s) to approve";
      this.notificationService.warning(this.message);
      return;
    }
    const needRefresh: boolean = await this.modalService.ffpPlanBatchApprovalModal("Approve Plan Project(s)", selectedPlanProject);
    if (needRefresh) {
      this.refreshData();
    }
  }

  onSelectionChanged() {
    this.selectedRowCount = this.gridApi.getSelectedNodes().length;
  }

  showBatchApproval() {
    if (this.userProfile) {
      return this.userProfile.IsFfpAdmin;
    }
    return false;
  }

  async syncProject() {
    await this.modalService.ffpSyncProjectBuildoutResponseModalComponent(this.projectOid);
    window.location.reload();
  }

  async reProcess() {
    const ffpPlanInstances = this.gridApi.getSelectedRows() as FfpPlanInstance[];
    if (ffpPlanInstances.length > 0) {
      await this.modalService.ffpPlanReprocessAssignResponseModalComponent(ffpPlanInstances);
    }
    else {
      this.notificationService.error("Please select at least one service");
    }
  }

  async lockedDownProjectBuildout() {
    this.apiService.lockFfpProjectBuildout(this.projectOid).subscribe(
      () => {
        this.notificationService.success("Locked Down Region buildout successfully");
        this.loadingService.setLoading(false);
        super.setupFilterByLocalData();
      },
      (e: any) => {
        this.loadingService.setLoading(false);
        this.notificationService.error(e);
      });
  }

  // setActionCheckBoxAttributes call only once by loadCheckBox flag
  setActionCheckBoxAttributes() {
    if (this.showBatchApproval() && BuildoutPlansColumnDefinition?.length && !this.loadCheckBox) {
      const columnDefs = [];
      this.gridOptions.columnDefs.map((map) => {
        if (map.headerName === "Action") {
          columnDefs.push({
            ...map,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
          });
        } else {
          columnDefs.push(map);
        }
      });

      this.gridOptions.api.setColumnDefs(columnDefs);
      this.loadCheckBox = true;
    }
  }

  exportExcel() {
    const params = {
      exportMode: "xlsx",
      skipHeader: false,
      columnGroups: false,
      skipGroups: false,
      skipFooters: false,
      skipPinnedTop: false,
      skipPinnedBottom: false,
      allColumns: false,
      onlySelected: false,
      onlySelectedAllPages: false,
      fileName: "ApprovableRegionalPlans-" + getExportedExcelFileNameSuffix(),
      sheetName: "Approvable Regional Plans",
      columnKeys: this.gridOptions.columnApi.getAllColumns().filter((c) => c.getColDef().colId !== "action"),
    } as ExcelExportParams;

    this.loadingService.setLoading(true);
    this.gridApi.exportDataAsExcel(params);
    this.loadingService.setLoading(false);
  }
}
