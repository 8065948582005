<div class="title" #title>
  <div class="container container-max-width">
    <div>
      <h4>FFP Buildout</h4>
      <p>{{ approvedPlansCount }} of {{ rowData.length }} approved plans on {{project.Title}} ({{ projectOid }})</p>
    </div>
    <div class="action-container" *ngIf="isFfpAdmin || isAdmin">
        <div class="flex-center" *ngIf="showBatchApproval()">
            <span>{{ selectedRowCount }} of {{ rowData.length }} selected &nbsp;&nbsp;|&nbsp;&nbsp;</span>
        </div>
        <button *ngIf="isAdmin" type="submit" class="btn btn-primary" (click)="reProcess()">Reprocess</button>&nbsp;
        <button type="submit" class="btn btn-primary" (click)="syncProject()">Sync Project</button>&nbsp;
        <button type="submit" class="btn btn-success" [disabled]="!selectedRowCount" (click)="batchApproveFfpPlan()" *ngIf="showBatchApproval()">Approve</button>&nbsp;
        <button type="submit" class="btn btn-primary" [disabled]="approvedPlansCount != rowData.length || rowData.length == 0" (click)="lockedDownProjectBuildout()">Locked Down</button>
        <a href="javascript:void(0)"
           class="topbar-item"
           (click)="exportExcel()"
           placement="top-right"
           container="body"
           ngbTooltip="Export to Excel"
           aria-label="Export to Excel">
            <span class="far fa-file-excel fa-lg"></span>
        </a>
    </div>
  </div>
</div>
<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="landing-main mt-3">
    <ag-grid-angular
      #agGrid
      id="ViewGrid"
      style="width: 100%; height: 540px"
      class="ag-theme-balham"
      [masterDetail]="true"
      [gridOptions]="gridOptions"
      [rowData]="rowData"
      [excelStyles]="headerStyle"
      pagination="true"
      rowSelection="multiple"
      (gridReady)="onGridReady($event)"
      (selectionChanged)="onSelectionChanged()"
      (filterChanged)="onFilterChanged($event)">
    </ag-grid-angular>
  </div>
</div>
